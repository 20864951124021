import React, { useContext, useState, useEffect } from 'react'
import axios from 'axios'
import { AppContext } from '../components/AppContext'
import Button from '@material-ui/core/Button';

const Login = () => {
  const context = useContext(AppContext)
  const [authUri, setAuthUri] = useState('')

  const fetchAuthUri = async () => {
    const result = await axios.get("/authUri")
    setAuthUri(result.data)
  }

  useEffect(() => {
    fetchAuthUri()
  }, [])


  if (context.isAuthenticated)
    return (
      <Button variant="contained" color="primary" href='/'>
        Logout
      </Button>
    )

  return (
    <Button variant="contained" color="primary" href={authUri}>
      Connect to QuickBooks
    </Button>
  )
}

export default Login