import React, { useContext } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import Dashboard from '../views/Dashboard'
import { AppContext } from '../components/AppContext'


const Routes = () => {
  const context = useContext(AppContext)

  return (
    <Switch>
      <Route exact path="/dashboard">
        <Dashboard />
      </Route>
    </Switch>
  )
}

export default Routes