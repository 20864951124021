const generateReportData = (estimates, inventoryValuations, invoices) => {
  const ID_NAME_COL = 0
  const SKU_COL = 1
  const QTY_COL = 2


  const estimateErrs = {
    // Estimates w/ no ship date DOC
    noShipDate: [],
    // EStimas w/ Accepted/Rejected status
    acceptRejectStatus: []
  }

  // For each inventory item
  const customReportData =
    inventoryValuations.map(row => {
      const id = row.ColData[ID_NAME_COL].id
      let name = row.ColData[ID_NAME_COL].value
      const sku = row.ColData[SKU_COL].value
      const qty = Number(row.ColData[QTY_COL].value)
      let totalPendingQty = 0
      let totalAcceptedQty = 0
      let shipDate = null

      let totalInvoiceQty = 0
      invoices
        .forEach(
          inv => {
            inv.Line.forEach(l => {
              //
              // Process SalesItemLineDetail 
              //
              if (l.DetailType == "SalesItemLineDetail") {
                const sild = l.SalesItemLineDetail
                if (sild && sild.ItemRef.value == id) {
                  totalInvoiceQty += sild.Qty
                }
              }

              //
              // Process GroupLineDetail Lines
              // 
              if (l.DetailType == "GroupLineDetail") {
                l.GroupLineDetail.Line.forEach(gl => {
                  const sild = gl.SalesItemLineDetail
                  if (sild && sild.ItemRef.value == id) {
                    totalInvoiceQty += sild.Qty
                  }
                })
              }
            })
          }
        )

      // Search estimates for
      estimates
        // .filter(e => e.TxnStatus == "Accepted" || e.TxnStatus == "Pending")
        .forEach(
          est => {
            // Estimate has a ShipDate
            if (est.ShipDate) {
              est.Line.forEach(l => {
                //
                // Process SalesItemLineDetail 
                //
                if (l.DetailType == "SalesItemLineDetail") {
                  const sild = l.SalesItemLineDetail
                  if (sild && sild.ItemRef.value == id) {
                    if (est.TxnStatus == "Accepted") {
                      totalAcceptedQty += Number(sild.Qty)
                      shipDate = est.ShipDate
                    }
                    else if (est.TxnStatus == "Pending") {
                      totalPendingQty += Number(sild.Qty)
                      shipDate = est.ShipDate
                    }
                  }
                }

                //
                // Process GroupLineDetail Lines
                // 
                if (l.DetailType == "GroupLineDetail") {
                  l.GroupLineDetail.Line.forEach(gl => {
                    const sild = gl.SalesItemLineDetail
                    if (sild && sild.ItemRef.value == id) {
                      if (est.TxnStatus == "Accepted") {
                        totalAcceptedQty += Number(sild.Qty)
                        shipDate = est.ShipDate
                      }
                      else if (est.TxnStatus == "Pending") {
                        totalPendingQty += Number(sild.Qty)
                        shipDate = est.ShipDate
                      }
                    }

                  })
                }
              })

              if (est.TxnStatus == "Accepted" || est.TxnStatus == "Rejected") {
                if (!estimateErrs.acceptRejectStatus.includes(est.DocNumber)) {
                  estimateErrs.acceptRejectStatus.push(est.DocNumber)
                }
              }
            }
            else { // Estimate has no ShipDate
              if (!estimateErrs.noShipDate.includes(est.DocNumber)) {
                // Exclude closed estimates
                if (est.TxnStatus != "Closed") {
                  estimateErrs.noShipDate.push(est.DocNumber)
                }
              }
            }
          }
        )

      return (
        {
          // Column 1
          // ID Name "Pump"
          id,
          name,
          sku,
          // Column 2
          // Qty in the inventory - minus qty from future invoices
          qty: (qty - totalInvoiceQty).toFixed(2),
          // Column 3
          // Pending QTY ( iterate through all estimates above for matching ID Name and return QTY)
          // TODO: Col 3 iterate through TXNStatus "pending" for all estimates for matching ID Name and Total QTY for the ID name
          pendingQty: totalPendingQty.toFixed(2),
          // Column 5
          // Total for Column 2 + Column 3
          col5: (qty - totalPendingQty).toFixed(2)
        }
      )
    })

  // customReportData.sort((a, b) => {
  //   let res = 0
  //   if (a.shipDate && b.shipDate) {
  //     res = (new Date(b.shipDate)) - (new Date(a.shipDate))
  //   }
  //   else {
  //     res = -1;
  //   }
  //   return res
  // })

  return { inventoryValidationReport: customReportData, estimateErrs }
}

export { generateReportData }